import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import index from '../views/index.vue'
import h5 from '../views/h5/index'
import aes from "@/utils/aes";

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '*',
		redirect: "/home"
	}, 
	{
		path: '/home',
		component: resolve => require(['../views/home/home'], resolve)
	},
	{
		path: '/test',
		component: resolve => require(['../views/sys/test.vue'], resolve)
	},
	{
		path: '/report',
		component: resolve => require(['../views/ivst/report.vue'], resolve)
	},
	{
		path: '/index',
		name: 'index',
		component: index,
		children: [
			
			
			{
				path: '/sys_menu',
				component: resolve => require(['../views/sys/sys_menu'], resolve)
			},
			{
				path: '/role',
				component: resolve => require(['../views/sys/sys_role'], resolve)
			},
			
			{
				path: '/init',
				component: resolve => require(['../views/sys/theme'], resolve)
			},
			{
				path: '/enterprise',
				component: resolve => require(['../views/pages/enterprise'], resolve)
			},
			{
				path: '/sch_enter',
				component: resolve => require(['../views/pages/sch_enter'], resolve)
			},
			{
				path: '/schxf',
				component: resolve => require(['../views/pages/sch_xunfang.vue'], resolve)
			},
			{
				path: '/xunfang',
				component: resolve => require(['../views/pages/sch_xunfangJyj.vue'], resolve)
			},
			
			{
				path: '/bxlist',
				component: resolve => require(['../views/pages/jyj_baoxian.vue'], resolve)
			},{
				path: '/baoxian',
				component: resolve => require(['../views/pages/sch_baoxian.vue'], resolve)
			},
			{
				path: '/shixi',
				component: resolve => require(['../views/pages/sch_shixi.vue'], resolve)
			},{
				path: '/sxdata',
				component: resolve => require(['../views/pages/jyj_shixi.vue'], resolve)
			},{
				path: '/bygz',
				component: resolve => require(['../views/pages/sch_bygz.vue'], resolve)
			},{
				path: '/setting',
				component: resolve => require(['../views/pages/theme.vue'], resolve)
			},{
				path: '/teachers',
				component: resolve => require(['../views/sys/sch_teacher.vue'], resolve)
			},{
				path: '/schools',
				component: resolve => require(['../views/sys/schools.vue'], resolve)
			},{
				path: '/jiuyebzr',
				component: resolve => require(['../views/pages/sch_jiuye_bzr.vue'], resolve)
			},{
				path: '/jiuyehz',
				component: resolve => require(['../views/pages/sch_jiuye.vue'], resolve)
			},{
				path: '/jysheet',
				component: resolve => require(['../views/pages/sch_jysheet.vue'], resolve)
			},{
				path: '/flsheet',
				component: resolve => require(['../views/pages/sch_flsheet.vue'], resolve)
			},{
				path: '/tjflsheet',
				component: resolve => require(['../views/pages/jyj_fenlei.vue'], resolve)
			},{
				path: '/tjsheet',
				component: resolve => require(['../views/pages/sch_tjsheet.vue'], resolve)
			},{
				path: '/tjjiuye',
				component: resolve => require(['../views/pages/jyj_jiuyetj.vue'], resolve)
			},{
				path: '/diaocha',
				component: resolve => require(['../views/pages/jyj_jiuye.vue'], resolve)
			},{
				path: '/tjmingce',
				component: resolve => require(['../views/pages/jyj_mingce.vue'], resolve)
			},{
				path: '/tjjysheet',
				component: resolve => require(['../views/pages/jyj_jiuye.vue'], resolve)
			},{
				path: '/sheet',
				component: resolve => require(['../views/pages/sch_sheet.vue'], resolve)
			},{
				path: '/teldata',
				component: resolve => require(['../views/pages/teldata.vue'], resolve)
			},{
				path: '/ivst_list',
				component: resolve => require(['../views/ivst/list.vue'], resolve)
			},{
				path: '/ivst_edit/:id',
				component: resolve => require(['../views/ivst/edit.vue'], resolve)
			},{
				path: '/ivst_plan',
				component: resolve => require(['../views/ivst/plan.vue'], resolve)
			},{
				path: '/zydltj',
				component: resolve => require(['../views/pages/jyj_zydltj.vue'], resolve)
			}
			
			
			
		]
	},
	//手机端的页面放这里
	{
		path: "/h5",
		component: h5,
		redirect: "/h5_plan_list",
		children: [
			{ path: '/ivst', name: 'ivst', component: resolve => require(['../views/h5/ivst'], resolve), },
			{ path: '/p', name: 'wjwelcome', component: resolve => require(['../views/h5/welcomeBeforeSession.vue'], resolve), },


			{ path: '/h5_plan_list', component: resolve => require(['../views/h5/planlist.vue'], resolve) },
			{ path: '/h5_ivst_plan', component: resolve => require(['../views/h5/ivstplan.vue'], resolve) },
			{ path: '/h5_ivst', component: resolve => require(['../views/h5/ivst.vue'], resolve) },
			{ path: '/quxiang', component: resolve => require(['../views/h5/quxiang.vue'], resolve) },
			{ path: '/jyzd', component: resolve => require(['../views/h5/shixizhidao.vue'], resolve) },
			{ path: '/h5_exam_list', component: resolve => require(['../views/h5/examlist.vue'], resolve) },
			{ path: '/njs', component: resolve => require(['../views/h5/njjsstu.vue'], resolve) },
			{ path: '/ivstchs', component: resolve => require(['../views/h5/ivstchs.vue'], resolve) },
		


			// {
			// 	path: '/ivst',
			// 	name: 'ivst',
			// 	component: resolve => require(['../views/h5_c_nw/ivst'], resolve),
			// },
			// {
			// 	path: '/p/:id',
			// 	name: 'ivstplan',
			// 	component: resolve => require(['../views/h5_c_nw/ivstplan'], resolve),
			// }, {
			// 	path: '/welcome',
			// 	name: 'welcome',
			// 	component: resolve => require(['../views/h5_c_nw/welcome'], resolve),
			// },
		]
	}
	
	
]



/* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
// 先存储默认底层的push
const originPush = VueRouter.prototype.push
// 覆盖原型链的push
VueRouter.prototype.push = function (location, resolve, reject) {
	// this：路由实例对象	
	// 判断用户有没有传后面两个可选参数
	if (resolve || reject) {
		return originPush.call(this, location, resolve, reject)
	} else { // 用户只传了第一个参数
		/* 
		默认底层： catch()方法代码  throw err : 抛出异常
		吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
		*/
		return originPush.call(this, location).catch(err => {
			//  throw err
		})
	}
}



const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach(async (to, from, next) => {
	// http://localhost:9020/#/p/211.53
	// http://localhost:9020/#/p?p=211.53
	let auth = $request("auth"); //检查url是否带有登录字符串
	let whiteList = ['/login', '/p', '/h5_plan_list', '/h5_ivst_plan', '/h5_ivst', '/welcome', '/quxiang', '/njs', '/ivstchs',
		'/ems_postFilesRedirect', '/ems_postFilesWithCover',
		'/bygz_ivst', '/bygz_p/']
	console.log('to.path', to.path, whiteList.indexOf(to.path), to.path.indexOf('/p'))
	console.log('window.location.href', window.location.href)
	console.log(1111111111)

	if (whiteList.indexOf(to.path) >= 0 || to.path.indexOf('/p') >= 0) {
		console.log(2222222222)
		next()
	} else if (auth) {
		window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
		//移除url的auth并刷新页面
		let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
		window.location.href = localUrl
		window.location.reload()
	} else { //跳转统一登录
		if (window.localStorage.getItem("auth")) { //检查本地是否登录
			if (localStorage.getItem('menus') && to.path.indexOf('ivst_edit') < 0) {
				let menus = await aes.decrypt(localStorage.getItem('menus'))
				try {
					menus = JSON.parse(menus)
				} catch (e) {
					console.log(e)
					menus = []
				}
				if (menus.indexOf(to.path) < 0) {
					next({path: menus[0]})
				} else {
					next()
				}
			} else {
				next()
			}
		} else {
			// console.log('to.path', to.path)
			// if (whiteList.indexOf(to.path) >= 0 || to.path.indexOf('/p/') >= 0) {
			// 	console.log('to.path', 111111)
			// 	next()
			// } else {
			console.log('to.path', 222222)
			console.log(241241241, '/login')

				next({ path: "/login" })
			// }
		}
	}
})



export default router
