<template>
	<div class="loginbg">



		<div class="login_box">
			<div style="padding: 20px; height: 100%; box-sizing: border-box;display: flex;">
				<div style="width: 0%; flex-grow: 1;border-right: 1px solid #8ea2b3; height: 100%;">
					<div class="appname">
						<span id="title_en">南京市职业学校就业调查平台</span>
						<div id="title_ch" class="paragraph_1">Nanjing Vocational School Employment Survey Platform
						</div>
					</div>
				</div>
				<div style="width: 50%;height: 100%;">

					<div style="width:400px;padding: 20px 40px 40px 40px; ">

						<div class="style_yhdl"
							style="text-align: center;padding-bottom: 40px;">
							用 户 登 录
						</div>

						<el-form :model="EditItem" style="height:250px !important">
							<el-form-item>
								<div style="border-bottom: 1px solid #8ea2b3; color: #fff; position: relative; ">
									<input class="inputbox" placeholder="请输入账号" @keyup.enter="login"
										v-model="username" />
									<div class="inputicon1">

										<i class="el-icon-user"></i>
									</div>
								</div>
							</el-form-item>

							<el-form-item>
								<div style="border-bottom: 1px solid #8ea2b3; color: #fff; position: relative; ">
									<input class="inputbox" type="password" placeholder="请输入密码" @keyup.enter="login"
										v-model="password" />
									<div class="inputicon1">

										<i class="el-icon-lock"></i>
									</div>
								</div>
							</el-form-item>

							<el-form-item v-if="false">
								<div style="border-bottom: 1px solid #8ea2b3; color: #fff; position: relative; ">
									<input class="inputbox" placeholder="请输入短信验证码" @keyup.enter="login"
										v-model="password" />
									<div class="inputicon1">

										<i class="el-icon-lock"></i>
									</div>

									<div class="btnsms">
										<el-button style="background-color: rgba(255,255,255,0.7);"
											:disabled="leftSecond != 120" size="mini" @click="sendSms">
											{{ leftSecond == 120 ? '发送验证码' : leftSecond + 'S后重发' }}
										</el-button>
									</div>


								</div>
							</el-form-item>


							<el-form-item style="height:50px">
								<div style="border-bottom: 1px solid #8ea2b3; color: #fff; position: relative; ">
									<input class="inputbox" placeholder="请输入字符验证码" @keyup.enter="login" v-model="yzm"
										style="width: 40%; " />
									<div class="inputicon1">

										<i class="el-icon-alarm-clock"></i>
									</div>

									<div style="position: absolute;display: flex; align-items: center; justify-content: center; right: -40px;top: 0px; text-align: center; height: 40px; overflow: hidden;"
										@click="changeYzm">
										<div style="border: 0;  width: 150px; height: 50px; transform: scale(0.8,0.8); transform-origin: left top;"
											v-html="yzmUrl">
										</div>

									</div>
								</div>





							</el-form-item>

							<div style="height: 20px; text-align: center; padding-top:20px; padding-bottom: 40px;">
								<el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
							</div>
						</el-form>


					</div>

				</div>
			</div>

		</div>
		<el-dialog title="初次登录请先修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<div style="text-align: center; color: orangered; padding-bottom: 20px;">密码为长度8-16位的字符、数字和特殊字符的组合</div>
			<el-form :model="pwdForm">

				<el-form-item>

					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">修改密码</span></template>
					</el-input>

				</el-form-item>

				<el-form-item>

					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">确认密码</span></template>
					</el-input>

				</el-form-item>


				<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
					<el-button :disabled="showbtn" type="primary" style="width: 100%;" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowModal: false,
			showbtn: false,
			showVideo: true,
			bgidx: "",
			bgvideourl: "",
			EditItem: {},
			username: "",
			password: "",
			session_id: "",
			smssession_id: "",
			yzmUrl: "",
			yzm: "",
			showQrcode: false,
			qrcodeUrl: "",
			qrcodeChecking: false,
			qrcodeTimer: -1,
			leftlogo: "",
			leftSecond: 120,
			loading: false,
			appname: "",
			pwdForm: {
				newPwd1: "",
				newPwd2: "",

			},
			pmid: 0
		}
	},
	mounted() {


		if (!window.pmid) {
			window.pmid = 1
		}
		this.getYZM()
		this.getConfig()

		$("#title_ch").width($("#title_en").width())
	},
	methods: {
		getConfig() {


			this.$http.post("/api/sch_config", { pmid: window.pmid }).then(res => {

				for (let k of res.data) {
					switch (k.ktype) {
						case 'leftlogo':
							this.leftlogo = k.kvalue
							break;
						case 'appname':
							this.appname = k.kvalue
							break;
						case 'schoolname':

							break;
						case 'favico':
							var link = document.createElement('link');
							link.type = 'image/x-icon';
							link.rel = 'shortcut icon';
							link.href = k.kvalue;
							document.getElementsByTagName('head')[0].appendChild(link);
							break;
					}
				}
			})

			document.title = "南京市职教教研室实习管理平台"
		},

		getQrcode() {
			this.$http.post("/api/login_qrcode", {
				pmid: window.pmid
			}).then(res => {

				this.smssession_id = res.data.session;

				this.qrcodeUrl = res.data.url;
				if (!this.qrcodeChecking) {
					this.qrcodeChecking = true

					this.checkQrcode();
				}
			})



		},
		checkQrcode() {

			this.qrcodeTimer = setInterval(() => {

				this.$http.post("/api/check_qrcode", {
					session_id: this.smssession_id,
					noloading: 1,
					pmid: window.pmid
				}).then(res => {
					if (res.data.auth) {
						if (res.data.pmid != window.pmid) {
							this.$message({
								message: "错误的二维码",
								type: 'error',
								offset: '600'
							});
						} else {
							if (res.data && res.data.auth) {

								localStorage.setItem("auth", res.data.auth)
								clearInterval(this.qrcodeTimer)
								// this.$router.push("/home")
								this.$router.push("/tjmingce")
							} else {
								if (res.data.msg) {
									this.$message({
										message: res.data.msg,
										type: 'error',
										offset: '600'
									});
								}

							}

						}

					}
				})
			}, 1000)
		},
		getYZM() {
			this.$http.post("/api/get_login_randomcode", {
				pmid: window.pmid
			}).then(res => {
				this.session_id = res.data.data.session_id;
				this.yzmUrl = res.data.data.svg;
			})
		},
		changeYzm() {
			this.getYZM()
		},
		login() {
			if (this.username.trim() && this.password.trim() && this.yzm.trim()) {//&& this.yzm.trim()
				this.$http.post("/api/admin_login", {
					loginname: this.username,
					mm: this.password,
					yzm: this.yzm.toLowerCase(),
					session_id: this.session_id,
					pmid: window.pmid,
					day: 7//7天后过期
				}).then(res => {
					if (res.data && res.data.auth) {

						localStorage.setItem("auth", res.data.auth)
						clearInterval(this.qrcodeTimer)

						this.pmid = res.data.pmid

						if (!res.data.upd_pwd) {
							this.isShowModal = true
						} else {
							if (res.data.pmid == 1) {
								// this.$router.push("/home")
								this.$router.push("/tjmingce")
							} else {
								this.$router.push("/jiuyebzr")
							}
						}

					} else {
						this.$message({
							message: res.data.msg,
							type: 'error',
							offset: '600'
						});

					}


				}, err => {

				})
			} else {
				this.$message({
					message: "好像没有填完整~",
					type: 'error',
					offset: '600'
				});
			}
		},
		sendSms() {

			if (this.leftSecond == 120) {

				const reg = /^1\d{10}$/;
				if (!reg.test(this.username)) {
					this.$message.error("手机号码格式有误！")
					return
				} else {
					if (this.loading) {
						return
					} else {
						this.loading = true
						setTimeout(() => {
							this.loading = false
						}, 3000);
						this.$http.post("/api/qsx_smsyzm", { pmid: window.pmid, phone: this.username, session_id: this.session_id }).then(ok => {

							this.$message.success("短信验证码发送成功！")
							this.leftSecond = 119;
							const timer = setInterval(() => {
								this.leftSecond--;
								if (this.leftSecond < 0) {
									this.leftSecond = 120;
									clearInterval(timer);
								}
							}, 1000);
						})
					}
				}


			}

		},
		changeLoginType() {
			this.showQrcode = !this.showQrcode
			if (this.showQrcode && !this.qrcodeUrl) {
				this.getQrcode()
			}
		},

		checkPassWord(value) {
			if (value.length < 8 || value.length > 16) { //最初级别
				this.$message.error("密码长度为8-16位")
				return false;
			}
			if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
				this.$message.error("密码必须包含数字")
				return false;
			}
			if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
				this.$message.error("密码必须包含大小写字母")
				return false;
			}

			var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
				regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

			if (!regEn.test(value) && !regCn.test(value)) {
				this.$message.error("密码必须包含特殊字符")
				return false;
			}

			return true


		},

		savePwd() {

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码")
				return
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码")
				return
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致")
				return
			}
			if (this.checkPassWord(this.pwdForm.newPwd2)) {
				this.showbtn = true;
				this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
					if (ok.data) {
						this.$message.success("修改成功,即将跳转")
						setTimeout(() => {
							if (this.pmid == 1) {
								// this.$router.push("/home")
								this.$router.push("/tjmingce")
							} else {
								this.$router.push("/jiuyebzr")
							}
						}, 3000)

					}
				})
			}


		},
	}
}
</script>

<style scoped>
/*解决chrome自带input伪类背景*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 111111s;
	-webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}

.loginbg {
	width: 100%;
	height: 100%;
	background-size: cover;
	position: relative;
	background-image: url(../../public/img/loginbg.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.login_box {

	width: 100%;
	height: 400px;
	background-color: rgba(0, 0, 0, 0.4);


}

.appname {
	padding-right: 40px;
	text-align: right;
	font-size: 36px;
	/*font-family: yahei;
	font-weight: bolder;*/
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;



}
		#title_en {
			/*width: 538px;*/
			height: 48px;
			font-family: MicrosoftYaHei, MicrosoftYaHei;
			font-weight: normal;
			font-size: 36px;
			color: #FFFFFF;
			line-height: 48px;
			letter-spacing: 5px;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}
.paragraph_1 {
	margin-top: 19px;
	text-align: justify;
	text-align-last: justify;
	font-size: 15px;
	font-weight: normal;
	/*width: 539px;*/
		height: 20px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 20px;
		letter-spacing: 3px;
		text-align: left;
		font-style: normal;
		text-transform: none;
}


.inputbox {
	width: 90%;
	padding: 10px 30px;

	border: 0;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0) !important;
	color: #fff !important;

	border-radius: 8px;
	position: relative;
	outline: none !important;
	font-size: 16px;
}

.inputbox::placeholder {
	color: #eee;
}

.inputicon1 {
	position: absolute;
	top: 0px;
	left: 0;
	font-size: 20px;
}

.btnsms {
	position: absolute;
	top: -2px;
	right: 10px;
	z-index: 2;
}



.style_yhdl {
	height: 34px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 600;
	font-size: 24px;
	color: #FFFFFF;
	line-height: 34px;
	letter-spacing: 3px;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

</style>
